<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('admin.labels.first_name')"
            label-for="account-first-name"
          >
            <b-form-input
              v-model="form.first_name"
              :placeholder="$t('admin.labels.first_name')"
              name="first_name"
              :state="errors && errors.first_name ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.first_name">
              {{ errors.first_name[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('admin.labels.last_name')"
            label-for="account-last-name"
          >
            <b-form-input
              v-model="form.last_name"
              name="last_name"
              :placeholder="$t('admin.labels.last_name')"
              :state="errors && errors.last_name ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.last_name">
              {{ errors.last_name[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('admin.labels.email')"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="form.email"
              name="email"
              :placeholder="$t('admin.labels.email')"
              :state="errors && errors.email ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.email">
              {{ errors.email[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('admin.labels.phone')"
            label-for="account-company"
          >
            <b-form-input
              v-model="form.phone"
              name="phone"
              :placeholder="$t('admin.labels.phone')"
              :state="errors && errors.phone ? false : null"
              disabled
            />
            <b-form-invalid-feedback v-if="errors && errors.phone">
              {{ errors.phone[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('admin.labels.password')"
            label-for="account-password"
          >
            <b-form-input
              v-model="form.password"
              name="password"
              :placeholder="$t('admin.labels.password')"
              :state="errors && errors.password ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.password">
              {{ errors.password[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          v-if="loaded"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="submit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import SweetAlertMixin from '@mixins/SweetAlertMixin'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      loaded: false,
      form: {
        first_name: null,
        last_name: null,
        password: null,
        email: null,
        phone: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  beforeCreate() {
    this.$http.get('/api/profile').then(res => {
      const {
        first_name, last_name, email, phone,
      } = res.data.data
      this.form = {
        first_name, last_name, email, phone,
      }
      this.loaded = true
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    submit() {
      const {
        first_name, last_name, email, password, phone,
      } = this.form

      const data = {
        first_name, last_name, email, password, phone,
      }

      this.$http.put('/api/profile', data)
        .then(response => {
          localStorage.setItem(useJwt.jwtConfig.userDataKey, JSON.stringify(response.data.data))

          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
